import React from 'react';
import AppNavigator from './navigations/AppNavigator';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/app.css';

function App() {
  return (
    <AppNavigator />
  );
}

export default App;
