import React, { useEffect, useState } from 'react';
import { Accordion, Card, Form, Spinner } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import { FaArrowCircleUp } from 'react-icons/fa';
import ScrollAnimation from 'react-animate-on-scroll';
import Props from '../configs/Props';
import Alert from '../components/Alert';
import { Utils } from '../configs/Utils';
import { Validations } from '../configs/Validations';
// import { Link as LinkB } from 'react-scroll';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { CarouselModel } from '../models/CarouselModel';
import imgBanner from '../images/img-1.png';
import imgProfile from '../images/ceo.png';
import styles from '../styles/home.module.css';

const Home = (props: Props) => {

    const [nameForm, setNameForm] = useState('');
    const [emailForm, setEmailForm] = useState('');
    const [numberPhoneForm, setNumberPhoneForm] = useState('');
    const [subjectForm, setSubjectForm] = useState('');
    const [messageForm, setMessageForm] = useState('');
  
    const [nameFormError, setNameFormError] = useState('');
    const [emailFormError, setEmailFormError] = useState('');
    const [numberPhoneFormError, setNumberPhoneFormError] = useState('');
    const [subjectFormError, setSubjectFormError] = useState('');
    const [messageFormError, setMessageFormError] = useState('');
  
    const [btnTxtForm, setBtnTxtForm] = useState<string | object>();

    const [showScroll, setShowScroll] = useState(false);

    // const [carousel, setCarousel] = useState<CarouselModel[]>();

    useEffect(() => {

        setBtnTxtForm("Enviar mensagem");

        window.addEventListener('scroll', checkScrollTop);
    
        // setCarousel([
        //   {
        //     img: img1,
        //     description: 'Regularize seu veículo!'
        //   },
        //   {
        //     img: img2,
        //     description: 'Mantenha seus documentos em dia!'
        //   },
        //   {
        //     img: img3,
        //     description: 'Conteste suas multas!'
        //   }
        // ]);
    }, []);

    const handleSubmitContact = (event: React.FormEvent<HTMLFormElement>) => {

        setBtnTxtForm(<Spinner animation="grow" variant="dark" size="sm" />);
    
        var nameError = Validations.validateRequiredField(nameForm, 'nome');
        var numberPhoneError = Validations.validateRequiredField(numberPhoneForm, 'e-mail');
        var emailError = Validations.validateRequiredField(emailForm, 'e-mail');
        var subjectError = Validations.validateRequiredField(subjectForm, 'assunto');
        var messageError = Validations.validateRequiredField(messageForm, 'mensagem');
    
        if(nameError || emailError || messageError || numberPhoneError || subjectError)
        {
            setNameFormError(nameError);
            setEmailFormError(emailError);
            setNumberPhoneFormError(numberPhoneError);
            setSubjectFormError(subjectError);
            setMessageFormError(messageError);
    
            setBtnTxtForm("Enviar mensagem");
        }
        else
        {
                
          const sendEmailForm = async () => {
              
            emailjs.sendForm('service_bp', 'template_bp', event.currentTarget, 'user_jrKxFExF1PxXI6L1i1pKm')
            .then(_ => {
                Utils.alertLocalStorage('Mensagem enviada com sucesso!', true);
                setBtnTxtForm("Enviar mensagem");
            })
            .catch(error => {
                console.log(error);
                Utils.alertLocalStorage(`Ocorreu um erro ao enviar a mensagem!`, false);
                setBtnTxtForm('Enviar mensagem');
            });
          }
          sendEmailForm();
        }
    
        event?.preventDefault();
    }

    const checkScrollTop = () => {

        if (window.pageYOffset > 400) {
          setShowScroll(true);
        } 
        else if (window.pageYOffset <= 400) {
          setShowScroll(false);
        }
    };

    const scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
     };
    
    return (
        <div>
            <Alert state={props.location.state} />

            <FaArrowCircleUp 
                className={`${styles.scrollTop}`}
                onClick={scrollTop} 
                style={{height: 40, display: showScroll ? 'flex' : 'none'}}
            />

            {/* <Carousel controls={false} interval={5000}>
                {carousel ? carousel.map((item, key) => (

                    <Carousel.Item key={key}>
                        <div className={`row ${styles.carousel_item}`} style={{ backgroundImage: `url('${item.img}')` }}>
                            <div className={`${styles.div_carousel_item}`}>
                                <h2 className={`h ${styles.h_carousel_item}`}>
                                    {item.description}
                                </h2>

                                <LinkB className={`${styles.font_btn_carousel}`} to="contact" spy={true} smooth={true} duration={500}>
                                    Entre em contato agora é saiba mais!
                                </LinkB>
                            </div>
                        </div>
                    </Carousel.Item>
                ))
                : <Spinner animation="grow" variant="dark" size="sm" />}
            </Carousel> */}

            <div className={`w-100`}>
                <img src={imgBanner} className={`${styles.img_banner} img-fluid`} />
            </div>

            <div className={`text-center`}>

                <div className={`${styles.section} ${styles.bg_snow}`} id='company'>
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <h2 className={`h ${styles.h_default}`}>The company</h2>
                    </ScrollAnimation>

                    <div className={`${styles.sub_menu}`}>
                        <p>
                            BPA was founded with the main objective of supporting foreign companies that 
                            intend to start operations in Brazil, either directly or through local 
                            partnerships or joint ventures.
                        </p>
 
                        <p>
                            The specialties of the partners and staff, all with extensive senior experience 
                            in various fields of activity, including easy access to the most important companies 
                            and Federal, State and Municipal Governments, allowing security, assertiveness and agility 
                            to projects.
                        </p>
                    </div>
                </div>

                <div className={`${styles.section}`} id='services'>
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <h2 className={`h ${styles.h_default}`}>Expertise & Services</h2>
                    </ScrollAnimation>

                    <div className={`${styles.sub_menu}`}>
                        <Accordion>
                            <Card className={`${styles.card_toggle}`}>
                                <Card.Header className={`p-0`}>
                                <Accordion.Toggle eventKey="0" className={`${styles.accord_toggle} w-100`}>
                                    <div className={`row`}>
                                        <div className={`col-md-9 d-flex justify-content-start`}>
                                            Total support for start up operations in Brazil
                                        </div>
                                        <div className={`col-md-3 d-flex justify-content-end`}>
                                            <button className={`${styles.link_toggle}`}>
                                                + Detalhes
                                            </button>
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <p>
                                        Companies need to think globally, but act locally. This is one of the most important factors in establishing successful start up operations in a new market.
                                    </p>

                                    <h6 className={`h ${styles.h_sub_menu_toggle}`}>Our advisory services comprise:</h6>

                                    <ul className={`${styles.ul_toggle}`}>
                                        <li>Full presentation of the Brazilian market and potential</li>
                                        <li>Development of business plans.</li>
                                        <li>Presentation of appropriate strategies to succeed, outlining strengths, weaknesses, competitors, most suitable markets, forward-looking statements and results for the next five years.</li>
                                        <li>Support the start-up of new operations.</li>
                                        <li>Support in managing pre- and post operational facilities in the first or/and second years.</li>
                                        <li>Extensive tax and regulatory expertise.</li>
                                        <li>Legal support in taxation and agreements.</li>
                                        <li>Support in finding local resources for investments.</li>
                                    </ul>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card className={`${styles.card_toggle}`}>
                                <Card.Header className={`p-0`}>
                                <Accordion.Toggle eventKey="0" className={`${styles.accord_toggle} w-100`}>
                                    <div className={`row`}>
                                        <div className={`col-md-9 d-flex justify-content-start`}>
                                            Establishment of joint ventures and partnerships
                                        </div>
                                        <div className={`col-md-3 d-flex justify-content-end`}>
                                            <button className={`${styles.link_toggle}`}>
                                                + Detalhes
                                            </button>
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <p>
                                        We possess the necessary expertise to assist you manage projects of several complexities, 
                                        using a diverse set of tools and advisory:
                                    </p>

                                    <ul className={`${styles.ul_toggle}`}>
                                        <li><b>Project scope:</b> Establish a project scope and its basic parameters.</li>
                                        <li><b>Implementation activities:</b> Selection of suppliers, engineering and construction resources, inspection, expediting and related activities.</li>
                                        <li><b>Termination activities:</b> Related to testing, acceptance, start up, commercial operation and management, final cost report, contract closing and arbitration, if needed.</li>
                                    </ul>

                                    <p>
                                        The creation of integrated project teams with excellent communication and negotiation skills, 
                                        setting precise, attainable  and clear project goals are a must for the efficient development of 
                                        all our projects.
                                    </p>

                                    <p>
                                        Joint ventures and partnerships are one of the most appropriate legal structures to address 
                                        specific projects, that may be larger or more complex than what previous experience and risk 
                                        analysis recommend taking on. Such structures are appropriate to comply with bidding requirements 
                                        and limit risk and financial liabilities. 
                                    </p>

                                    <h6 className={`h ${styles.h_sub_menu_toggle}`}>
                                        We may assist you in:
                                    </h6>

                                    <ul className={`${styles.ul_toggle}`}>
                                        <li>Locating the most appropriate partner with complementary abilities, resources and capacities, 
                                            define clear and concise objectives, advise on the balance of resources, risk allocation, 
                                            financial liability and proprietary know-how protection.</li>
                                        <li>A partnership of any type will require the clear establishment of structures, objectives, 
                                            financial and technical contributions, profit distribution and transfer of technology.</li>
                                        <li>Agreeing on fair and equitable financial and technical contributions.</li>
                                        <li>Establishing fair distribution methods for expenses, profits, contributions and risk.</li>
                                        <li>Assisting in the resolution of disputes and discrepancies.</li>
                                        <li>Establishing the appropriate exit strategies for investors, partners and associates.</li>
                                    </ul>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card className={`${styles.card_toggle}`}>
                                <Card.Header className={`p-0`}>
                                <Accordion.Toggle eventKey="0" className={`${styles.accord_toggle} w-100`}>
                                    <div className={`row`}>
                                        <div className={`col-md-9 d-flex justify-content-start`}>
                                            Management and development of projects across several industries
                                        </div>
                                        <div className={`col-md-3 d-flex justify-content-end`}>
                                            <button className={`${styles.link_toggle}`}>
                                                + Detalhes
                                            </button>
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <p>
                                        Continuous presence and competition of large consortiums and conglomerates increases competition in all markets.
                                    </p>
                                    
                                    <p>
                                        Companies need to be aggressive and not only go out and look for new contracts but also create them by  effective promotion and analysis of potential client needs and requirements. 
                                    </p>

                                    <h6 className={`h ${styles.h_sub_menu_toggle}`}>
                                        Our expertise includes:
                                    </h6>

                                    <ul className={`${styles.ul_toggle}`}>
                                        <li>Identification of targets.</li>
                                        <li>Identify new markets and technologies for implementation and development.</li>
                                        <li>Assessment of existing company structures in light of demands for new market penetration.</li>
                                        <li>The selection of local partners, associates and representatives.</li>
                                        <li>Data collection to identify, define and measure local competitors.</li>
                                        <li>Advisory in the definition of specific market goals, market share and general marketing strategies.</li>
                                        <li>Proposing new markets, establishing niche markets and others.</li>
                                    </ul>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card className={`${styles.card_toggle}`}>
                                <Card.Header className={`p-0`}>
                                <Accordion.Toggle eventKey="0" className={`${styles.accord_toggle} w-100`}>
                                    <div className={`row`}>
                                        <div className={`col-md-9 d-flex justify-content-start`}>
                                            Assessment of project risks
                                        </div>
                                        <div className={`col-md-3 d-flex justify-content-end`}>
                                            <button className={`${styles.link_toggle}`}>
                                                + Detalhes
                                            </button>
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <p>
                                        Any project is subject to risk, from both external sources and market variations and 
                                        internal sources. 
                                    </p>

                                    <h6 className={`h ${styles.h_sub_menu_toggle}`}>
                                        We will assist you with:
                                    </h6>

                                    <ul className={`${styles.ul_toggle}`}>
                                        <li>Impeding causes of major risk by proper evaluation of contractors, suppliers and project participants in general.</li>
                                        <li>Evaluation of control procedures and methods.</li>
                                        <li>Establishment of adequate documentation and control procedures during different phases of the project.</li>
                                        <li>Attention in cases where failure to act promptly may cause risk of failure, additional expenditures, cost overrun and loss of production and progress.</li>
                                        <li>Recommend mitigation and remediation procedures.</li>
                                        <li>Advise on general and performance insurance underwritting.</li>
                                        <li>Assist in emergencies and events of force majeure.</li>
                                    </ul>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card className={`${styles.card_toggle}`}>
                                <Card.Header className={`p-0`}>
                                <Accordion.Toggle eventKey="0" className={`${styles.accord_toggle} w-100`}>
                                    <div className={`row`}>
                                        <div className={`col-md-9 d-flex justify-content-start`}>
                                            Selection of suppliers and service providers
                                        </div>
                                        <div className={`col-md-3 d-flex justify-content-end`}>
                                            <button className={`${styles.link_toggle}`}>
                                                + Detalhes
                                            </button>
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <p>
                                        Many projects will require a set of goods and services that are not in the scope of supply 
                                        by one single company. The selection of suppliers, subcontractors and providers of goods and 
                                        services is an important feature of a project, in order to have it evolve in a controlled and 
                                        efficient manner, resulting in net positive profits.  Suppliers and providers may assist a 
                                        contractor by reducing costs, transportation time, risk, lack of experience and other aspects. 
                                        Local providers may assist in political and local requirements for the promotion of local jobs 
                                        and the Brazilian economy. 
                                    </p>
                                    
                                    <h6 className={`h ${styles.h_sub_menu_toggle}`}>
                                        We may assist you with:
                                    </h6>

                                    <ul className={`${styles.ul_toggle}`}>
                                        <li>Suggesting suppliers and providers,  and selecting them by pre-qualification, evaluation, 
                                            competitive tender or availability to increase project profitability due to quality, cost, 
                                            delivery and other qualifications.</li>
                                        <li>Suggesting providers as a means of standardization of equipment to reduce spares and stock of parts, 
                                            reduce personnel training, facilitate maintenance and other-related aspects.</li>
                                        <li>Establishing competitive and fair selection and purchasing procedures and tendering processes.</li>
                                        <li>Establishing proper procurement, inspection, expediting and quality control procedures.</li>
                                    </ul>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card className={`${styles.card_toggle}`}>
                                <Card.Header className={`p-0`}>
                                <Accordion.Toggle eventKey="0" className={`${styles.accord_toggle} w-100`}>
                                    <div className={`row`}>
                                        <div className={`col-md-9 d-flex justify-content-start`}>
                                            Prospection of corporate clients in local and overseas markets
                                        </div>
                                        <div className={`col-md-3 d-flex justify-content-end`}>
                                            <button className={`${styles.link_toggle}`}>
                                                + Detalhes
                                            </button>
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <p>
                                        General information about the country where you want to develop operations is not enough to know the real potential for your business, nor how to find potential  corporate clients. 
                                    </p>

                                    <h6 className={`h ${styles.h_sub_menu_toggle}`}>
                                        We may assist you with:
                                    </h6>

                                    <ul className={`${styles.ul_toggle}`}>
                                        <li>Developing the necessary studies in your target market to determine its size, dynamic operation, 
                                            distribution channels and key players.</li>
                                        <li>Defining the cost of importation goods in the country and sales prices compared to similar products.</li>
                                        <li>Defining the best approach and an interesting product mix customized to target corporate clients on an 
                                            individual basis.</li>
                                        <li>Present or provide support to present business opportunities to potential corporate clients.</li>
                                    </ul>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Accordion>
                            <Card className={`${styles.card_toggle}`}>
                                <Card.Header className={`p-0`}>
                                <Accordion.Toggle eventKey="0" className={`${styles.accord_toggle} w-100`}>
                                    <div className={`row`}>
                                        <div className={`col-md-9 d-flex justify-content-start`}>
                                            Relationship management
                                        </div>
                                        <div className={`col-md-3 d-flex justify-content-end`}>
                                            <button className={`${styles.link_toggle}`}>
                                                + Detalhes
                                            </button>
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <p>
                                        Companies may seek new markets overseas either alone or by partnering with local companies. 
                                        In both cases, especially in the case of starting operations alone, it is important to be advised 
                                        by a professional with extensive relationships in your prospective market. This may asisst you 
                                        develop relationships with goverments and local players, especially in relationship-driven cultures.
                                    </p>

                                    <ul className={`${styles.ul_toggle}`}>
                                        <li>Our professionals, including our local partners, have extensive relations across several areas, 
                                            including the Federal, State and Municipal Governments, reaching the highest authorities. 
                                            We are also actively present participating in commerce and industry federations and associations 
                                            throughout Brazil.</li>
                                        <li>Access to senior  Government members.</li>
                                        <li>Access to regulatory and licensing activities entities.</li>
                                    </ul>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                </div>

                <div className={`${styles.section} ${styles.bg_snow}`} id='team'>
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <h2 className={`h ${styles.h_default}`}>Team & partners</h2>
                    </ScrollAnimation>

                    <div className={`${styles.sub_menu}`}>
                        <div className={`row`}>
                            <div className={`col-md-6`}>
                                <h4 className={`h ${styles.h_sub_menu}`}>Team:</h4>

                                <ul className={`${styles.ul_person}`}>
                                    <li>Elizeu Lima</li>
                                    <li>Aimone Summa Júnior</li>
                                    <li>José Carlos Franco Borba</li>
                                    <li>Lourival Abrao</li>
                                    <li>Henry Klein</li>
                                </ul>
                            </div>
                            <div className={`col-md-6`}>
                                <h4 className={`h ${styles.h_sub_menu}`}>Partners:</h4>

                                <ul className={`${styles.ul_person}`}>
                                    <li>Éric Butcher - Spain 🇪🇸 and Middle East </li>
                                    <li>Ravi Nara - Índia 🇮🇳 </li>
                                    <li>Alan Hart - UK 🇬🇧 </li>
                                    <li>Chang Ng - Panamá 🇵🇦 </li>
                                    <li>Jixu Cai - China 🇨🇳 </li>
                                    <li>Manuel Gudina - Spain 🇪🇸 and Portugal 🇵🇹 </li>
                                    <li>Luís Ramires - México 🇲🇽 </li>
                                    <li>Kim Yuong - Korea </li>
                                    <li>Hiroshi Iwamoto - Japan 🇯🇵 </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.section}`} id='contact'>
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <h2 className={`h ${styles.h_default}`}>Contact</h2>
                    </ScrollAnimation>

                    <div className={`${styles.sub_menu}`}>

                        <div className={`row`}>
                            <div className={`col-md-6 mt-4`}>

                                <div className={`mb-3`}>
                                    <ScrollAnimation animateIn='flipInY' animateOut='flipOutY' animateOnce={true}>
                                        <img src={imgProfile} className={`${styles.img_profile} img-fluid`} />
                                    </ScrollAnimation>
                                </div>

                                <h6 className={`h`}>Elizeu Lima</h6>

                                <a href='https://api.whatsapp.com/send?phone=5511999915439' target='_blank' className={`${styles.link_form}`}>
                                    (11) 99991-5439
                                </a>

                                <a href='mailto:elizeu@bpassociados.com' target='_blank' className={`${styles.link_form}`}>
                                    elizeu@bpassociados.com
                                </a>                   
                            </div>
                            <div className={`col-md-6 mt-4`}>
                                
                                <Form onSubmit={handleSubmitContact} className={`d-flex flex-column`}>
                                    <Form.Group controlId="formName" className={`d-flex flex-column align-items-start`}>
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Name" 
                                            value={nameForm}
                                            onChange={(e) => setNameForm(e.target.value)}
                                            name='name'
                                        />
                                        <Form.Text className="text-danger">
                                            {nameFormError}
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formNumberPhone" className={`d-flex flex-column align-items-start`}>
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Phone" 
                                            value={numberPhoneForm}
                                            onChange={(e) => setNumberPhoneForm(e.target.value)}
                                            name='numberPhone'
                                        />
                                        <Form.Text className="text-danger">
                                            {numberPhoneFormError}
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formEmail" className={`d-flex flex-column align-items-start`}>
                                        <Form.Control 
                                            type="text" 
                                            placeholder="E-mail" 
                                            value={emailForm}
                                            onChange={(e) => setEmailForm(e.target.value)}
                                            name='email'
                                        />
                                        <Form.Text className="text-danger">
                                            {emailFormError}
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formSubject" className={`d-flex flex-column align-items-start`}>
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Subject" 
                                            value={subjectForm}
                                            onChange={(e) => setSubjectForm(e.target.value)}
                                            name='subject'
                                        />
                                        <Form.Text className="text-danger">
                                            {subjectFormError}
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formMessage" className={`d-flex flex-column align-items-start`}>
                                        <Form.Control 
                                            as="textarea" 
                                            rows={5} 
                                            placeholder="Message" 
                                            value={messageForm}
                                            onChange={(e) => setMessageForm(e.target.value)}
                                            name='message'
                                        />
                                        <Form.Text className="text-danger">
                                            {messageFormError}
                                        </Form.Text>
                                    </Form.Group>

                                    <button type="submit" className={`btn ${styles.btn_form}`}>
                                        {btnTxtForm}
                                    </button>
                                </Form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;