import React from 'react';
import styles from '../styles/footer.module.css';

const Footer = () => {
    return (
        <div>
            <div className={`${styles.footer}`}>
                <h6 className={`${styles.h_footer}`}>Copyright © BP Associados 2021 - Todos os direitos reservados.</h6>
                <a 
                    className={`${styles.span_footer}`}
                    href='https://bdg-digital.com'
                    target='_blank'
                >
                    Desenvolvido por BDG Digital
                </a>
            </div>
        </div>
    )
}

export default Footer;
