import React from 'react';
import { Link } from 'react-router-dom';
import { Link as LinkB } from 'react-scroll';
import Navbar from 'react-bootstrap/esm/Navbar';
import Nav from 'react-bootstrap/esm/Nav';
import styles from '../styles/header.module.css';
import logo from '../images/logo-header.png';

const Header = () => {

    return (
        <div>
            <Navbar className={`${styles.navbar}`} expand='md' collapseOnSelect>
                <Navbar.Brand as={Link} to='/'>
                    <img 
                        className={`${styles.img_logo} img-fluid`} 
                        src={logo}
                        alt='Logo'
                    />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="navbar-nav" className={`${styles.nav_toggle}`} />

                <Navbar.Collapse id="navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link as={LinkB} className={`${styles.nav_link}`} activeClass="active" to="home" spy={true} smooth={true} duration={1000}>Home</Nav.Link>
                        <Nav.Link as={LinkB} className={`${styles.nav_link}`} activeClass="active" to="company" spy={true} smooth={true} duration={1000}>The Company</Nav.Link>
                        <Nav.Link as={LinkB} className={`${styles.nav_link}`} activeClass="active" to="services" spy={true} smooth={true} duration={1000}>Expertise & Services</Nav.Link>
                        <Nav.Link as={LinkB} className={`${styles.nav_link}`} activeClass="active" to="team" spy={true} smooth={true} duration={1000}>Team & partners</Nav.Link>
                        <Nav.Link as={LinkB} className={`${styles.nav_link}`} activeClass="active" to="contact" spy={true} smooth={true} duration={1000}>Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default Header;
